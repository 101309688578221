#popWrap{
  position: fixed;
  z-index: 10000;
  top: 150px;
  left: 300px;
  width: auto;
}
#popTitle{
  text-align: center;
  background-color: #164c61bf;
}
#popTitle img{
  margin-top: 7px;  
}
#popBodyA{
  background-color: #d1d1d1dc;
  padding: 5px;
  display: flex;
  max-width: 940px;
  flex-wrap: wrap;
}
#popBodyB{
  background-color: #d1d1d1dc;
  padding: 5px;
  display: flex;
  max-width: 630px;
  flex-wrap: wrap;
}
.popCon{
  margin: 3px 5px 0 5px;
}
.popImg{
  width: 300px;
  height: 300px;
  cursor: pointer;
}
#popBtnWrap{
  background-color: rgba(0, 0, 0, .6);
  color: #fff;
  font-size: .9rem;
  font-weight: 400;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}
#closeBtn{
  background-color: rgba(199, 199, 199, 0.369);
  border: none;
  color: #fff;
  padding: 2px 10px;
  text-align: end;
  cursor: pointer;
}
#checkInput{
  zoom: 1.3;
  position: relative;
  top: 3px;
  margin-right: 5px;
  cursor: pointer;
}